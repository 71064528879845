import React from 'react';
import './UnderConstruction.css'; // Create this file for styling if needed

const UnderConstruction = () => {
  return (
    <div className="under-construction">
      <h1>Site Under Construction</h1>
      <p>We are working hard to bring you a new experience. Please check back soon!</p>
    </div>
  );
};

export default UnderConstruction;
