import React from 'react';
import UnderConstruction from './UnderConstruction';

function App() {
  return (
    <div className="App">
      <UnderConstruction />
    </div>
  );
}

export default App;

